<template>
	<div>
		<v-toolbar class="mb-auto" rounded="t-xl">
			<v-btn fab plain :to="{ name: 'User', params: { id: user.id } }">
				<v-avatar class="mr-2">
					<v-img alt="Avatar" :src="imgURL" />
				</v-avatar>
			</v-btn>
			<v-toolbar-title>
				{{ fullname }}
				<br />
				<router-link
					class="text-subtitle-2 text-uppercase text-decoration-none red--text"
					:to="{ name: fromOffer ? 'Offer' : 'Event', params: { id: parentID } }"
				>
					{{ title }}
				</router-link>
			</v-toolbar-title>
			<v-spacer />
		</v-toolbar>
		<v-card class="d-flex flex-column justify-space-between" rounded="b-xl" :style="{ height: chatHeight }">
			<div class="flex-grow-1 d-flex flex-column scrollable my-2 py-3" ref="messagesContainer">
				<template v-for="message in chat.messages">
					<template v-if="message.incoming">
						<ChatMessageIncoming :key="`message${message.id}`" :message="message" :chat="chat" />
					</template>
					<template v-else>
						<ChatMessageOutgoing :key="`message${message.id}`" :message="message" :chat="chat" />
					</template>
				</template>
				<template v-if="!chat.messages.length">
					<p class="text-center">{{ $t('chats.noMessages') }}</p>
				</template>
			</div>
			<v-textarea
				v-model="content"
				filled
				outlined
				clear-icon="mdi-close-circle"
				clearable
				hide-details
				:rows="1"
				auto-grow
				label="Message"
				:loading="loading"
				:append-icon="content ? 'mdi-send' : ''"
				@click:append="callSendMessage()"
				@click:clear="content = ''"
				class="flex-grow-0 flex-shrink-1 pt-1 overflow-auto"
				style="max-height: 50%"
			/>
		</v-card>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('nav.messages')} - ${this.fullname}`
		}
	},
	data() {
		return {
			loading: true,
			content: ''
		}
	},
	components: {
		ChatMessageIncoming: () => import('@/components/chats/ChatMessageIncoming.vue'),
		ChatMessageOutgoing: () => import('@/components/chats/ChatMessageOutgoing.vue')
	},
	computed: {
		chatHeight() {
			var x = this.$vuetify.breakpoint.height
			if (x > 2000) return '80vh'
			else if (x < 400) return '10vh'
			else
				return `${-4.25 * Math.pow(10, -11) * Math.pow(x, 4) +
					0.000000265 * Math.pow(x, 3) -
					0.0005776327 * Math.pow(x, 2) +
					0.5488767355 * x -
					117}vh`
		},
		fromOffer() {
			if (!this.chat) return null
			return !!this.chat.application
		},
		fromEvent() {
			if (!this.chat) return null
			return !!this.chat.attendance
		},
		user() {
			if (!this.chat) return null
			if (this.fromOffer) return this.chat.application.user
			else return this.chat.attendance.user
		},
		parentID() {
			if (!this.chat) return null
			if (this.fromOffer) return this.chat.offer.id
			else return this.chat.event.id
		},
		imgURL() {
			if (!this.chat) return null
			if (this.fromOffer) return this.chat.application.imgURL
			else return this.chat.attendance.imgURL
		},
		fullname() {
			if (!this.chat) return null
			if (this.fromOffer) return this.chat.application.fullname
			else return this.chat.attendance.fullname
		},
		title() {
			if (!this.chat) return null
			if (this.fromOffer) return this.chat.offer.title
			else return this.chat.event.title
		},
		...mapGetters({
			chat: 'chats/chat'
		})
	},
	watch: {
		'$route.params.id'(id) {
			this.fetchChat(id)
				.then(({ success }) => {
					if (success) {
						this.readAllMessages(id)
					}
				})
				.then(() => {
					this.loading = false
				})
		}
	},
	created() {
		this.fetchChat(this.$route.params.id)
			.then(({ success }) => {
				if (success) {
					this.readAllMessages(this.$route.params.id)
					this.scrollToBottom()
				}
			})
			.then(() => {
				this.loading = false
			})
	},
	methods: {
		scrollToBottom() {
			var messagesContainer = this.$refs.messagesContainer
			messagesContainer.scrollTop = messagesContainer.scrollHeight
		},
		callSendMessage() {
			this.loading = true
			this.sendMessage({ chat: this.chat, content: this.content }).then(({ success, message }) => {
				if (success) {
					this.chat.messages.push(message)
					this.content = ''
					this.scrollToBottom()
				}
			})
		},
		...mapActions('chats', ['fetchChat', 'readAllMessages', 'sendMessage'])
	}
}
</script>

<style scoped>
.scrollable {
	overflow-y: auto;
}
</style>
